import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import {
  blogSection,
  postsContainer,
  post,
  content,
  title,
  meta,
} from "../styles/blog.module.css"

const Blog = ({ data }) => {
  return (
    <Layout>
      <Seo title="Blog" />
      <section className={blogSection}>
        <h1>Blog - {data.allMarkdownRemark.totalCount} Posts</h1>
        <div className={postsContainer}>
          {data.allMarkdownRemark.edges.map(({ node }) => (
            <div key={node.id} className={post}>
              <Link to={node.fields.slug} aria-label={node.frontmatter.title}>
                <GatsbyImage
                  image={
                    node.frontmatter.featuredImage.childImageSharp
                      .gatsbyImageData
                  }
                  alt={node.frontmatter.featuredImage.name}
                />
                <div className={content}>
                  <p className={title}>{node.frontmatter.title}</p>
                  <span className={meta}>
                    {node.frontmatter.date} - {node.timeToRead} minute read
                  </span>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </section>
    </Layout>
  )
}
export const data = graphql`
  {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(fromNow: true)
            featuredImage {
              name
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          fields {
            slug
          }
          timeToRead
        }
      }
    }
  }
`

export default Blog
